import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/shop.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news.vue')
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('../views/details.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },

]

const router = new VueRouter({
  routes
})

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 设置跳转页面自动滚动到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
