<template>
  <div>
    <div class="mask"></div>
    <el-card>
      <slot></slot>
      <span class="tips">请填写以下信息，方便与您取得联系，已开启隐私保护</span>
      <div class="close" @click="handleClose">X</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <span>
            <el-input v-model="form.code" type="number" style="width: 73%;" placeholder="请输入验证码"></el-input>
          </span>
          <span>
            <el-button type="primary" v-if="!getCoding" style="width: 100px;padding:12px;margin-left: 10px;"
              @click="getCode">获取验证码</el-button>
            <el-button type="info" v-if="getCoding" style="width: 100px;padding:12px;margin-left: 10px;">重新获取({{ count
            }})</el-button>
          </span>
        </el-form-item>
        <el-form-item prop="company">
          <el-input v-model="form.company" placeholder="请输入公司"></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitMessage">提交</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getCode, addDemand } from '@/api'

let timer;

export default {
  props: {
    info: {
      required: true,
      type: Object
    }
  },
  data() {
    const validatePhone = (rules, value, callback) => {
      const reg = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
      if (!reg) {
        callback('请输入正确的手机号')
      } else {
        callback();
      }
    }
    return {
      form: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入公司', trigger: 'blur' },
        ],
      },
      getCoding: false,
      count: 60,
    }
  },
  methods: {
    async submitMessage() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.type = this.info.submitType;
          this.form.productId = this.info.productId;
          const res = await addDemand(this.form)
          console.log(res)
          if (res.code != 200) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('提交成功')
            this.handleClose();
          }
        }
      })
    },
    async getCode() {
      if (!this.form.phone) {
        this.$message.warning('请输入手机号')
        return
      }
      this.$refs.form.validateField("phone", async (valid) => {
        // 再次校验手机号
        if (!valid) {
          this.getCoding = true;
          timer = setInterval(() => {
            if (this.count == 0) {
              this.getCoding = false;
              this.count = 60;
              clearInterval(timer)
              return
            }
            this.count--
          }, 1000)
          await getCode({ phone: this.form.phone })
        };
      })
    },
    handleClose() {
      this.form = {};
      this.$refs.form.clearValidate();
      this.$emit("handleClose")
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
}

.el-card {
  width: 500px;
  padding: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 30;
  transform: translate(-50%, -50%);

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .tips {
    font-size: 13px;
    color: #999;
  }

  ::v-deep .el-form {
    margin-top: 20px;

    .el-input {


      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;

      }

      input::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }


    .el-button {
      width: 100%;
    }
  }
}
</style>