<template>
  <div class="home-container">
    <div class="banner-container">
      <p class="title">选软件，就上甄选型</p>
      <button @click="goShop">查看甄选</button>
      <el-carousel trigger="click" height="470px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.imageUrl" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <section>
      <ul>
        <li>
          <p class="item-data">
            <countTo :startVal="0" :endVal="runajianCount || 0" :duration="3000" />套
          </p>
          <p class="item-title">软件产品</p>
        </li>
        <li>
          <p class="item-data">
            <countTo :startVal="0" :endVal="yingjianCount || 0" :duration="3000" />类
          </p>
          <p class="item-title">硬件产品</p>
        </li>
        <li>
          <p class="item-data">
            <countTo :startVal="0" :endVal="serviceCount || 0" :duration="3000" />项
          </p>
          <p class="item-title">数据产品</p>
        </li>
        <li>
          <p class="item-data">
            <countTo :startVal="0" :endVal="fanganCount || 0" :duration="3000" />份
          </p>
          <p class="item-title">解决方案</p>
        </li>
      </ul>
    </section>
    <div class="product-container">
      <h2>最新需求</h2>
      <div class="product-top" v-if="demandList.length">
        <el-card shadow="hover" v-for="(item, index) in demandList" :key="index.id">
          <div slot="header" class="header">
            <h3>{{ item.name }}</h3>
            <h3>￥{{ item.price }}</h3>
          </div>
          <div class="content" v-html="item.introduce">
          </div>
          <div class="footer">
            <div class="day">{{ item.createTime | filterTime }}</div>
            <div class="btn">
              <el-button round size="small" class="canyu" @click="canyuClick(item)">参与需求</el-button>
              <el-button type="primary" round size="small" class="fabu" @click="fabuClick(item)">发布需求</el-button>
            </div>
          </div>
        </el-card>
      </div>
      <template v-else>
        <div class="no-data">暂无数据</div>
      </template>
      <h2>最新甄选</h2>
      <ul v-if="list.length">
        <li @click="goDetail(item)" v-for="(item, index) in list" :key="index">
          <img :src="JSON.parse(item.background)[0].url" alt="">
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <template v-else>
        <div class="no-data">暂无数据</div>
      </template>
    </div>

    <myDialog v-show="dialogVisible" :info="propsInfo" @handleClose="handleClose">
      <h3>咨询留言</h3>
    </myDialog>

  </div>
</template>

<script>
import countTo from 'vue-count-to';
import { getProductList, getTabData, addMessageDemand, getBannerList, getCount,getPageView } from '@/api'

import myDialog from '@/components/myDialog.vue'

export default {
  name: 'HomeView',
  components: {
    countTo,
    myDialog
  },
  data() {

    return {
      list: [],
      countList: [],
      phone: "",
      dialogVisible: false,
      bannerList: [],
      propsInfo: {},
      demandList: [],
      runajianCount:0,
      yingjianCount:0,
      serviceCount:0,
      fanganCount:0,
    }
  },
  filters: {
    filterTime(time) {
      // 解析日期时间字符串为Date对象  
      const date = new Date(time);
      const now = new Date();
      // 计算时间差（毫秒）  
      const diff = now - date;
      // 转换为秒、分钟、小时、天  
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      // 根据时间差返回相应的描述  
      if (days) {
        return `${days}天前`
      } else if (hours) {
        return `${hours}小时前`
      } else if (minutes) {
        return `${minutes}分钟前`
      } else {
        return `刚刚`
      }
    }
  },
  created() {
    this.getBanner();
    this.getCount();
    this.getProductList();
    this.getDict();
  },
  methods: {
    async getCount() {
      let res = await getCount();
      res.data.forEach(item => {
        if (item.type == 'ruanjian') {
          this.runajianCount = item.totalNum;
        } else if (item.type == 'yingjian') {
          this.yingjianCount = item.totalNum;
        } else if (item.type == 'service') {
          this.serviceCount = item.totalNum;
        } else if (item.type == 'fangan') {
          this.fanganCount = item.totalNum;
        }
      })
    },
    async getBanner() {
      let res = await getBannerList();
      this.bannerList = res.rows.reverse().slice(0, 5);
    },
    async submitSuggest() {
      this.$refs.suggestForm.validate(async valid => {
        if (valid) {
          this.suggestForm.type = this.propsInfo.submitType;
          const res = await addMessageDemand(this.suggestForm)
          if (res.code != 200) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('提交成功')
            this.handleClose();
          }
        }
      })
    },
    handleClose() {
      this.dialogVisible = false;
      this.suggestVisible = false;
      this.suggestForm = {};
      this.$refs.suggestForm.clearVfalidate();
    },
    canyuClick(item) {
      this.dialogVisible = true
      this.dialogTitle = ''
      // 为了收集系统名称
      this.propsInfo.productId = item.id;
      this.propsInfo.submitType = "CANYU"
    },
    fabuClick(item) {
      this.dialogVisible = true
      this.propsInfo.productId = item.id;
      this.propsInfo.submitType = "FABU"
    },
    async getDict() {
      let res = await getTabData({ dictType: 'phone' })
      this.phone = res.rows[0].dictValue
    },
    async getProductList() {
      let res = await getProductList({ status: 1, serviceType: 0, pageNum: 1, pageSize: 6 });
      this.list = res.rows
      this.list.forEach(item => {
        if (item.introduce) {
          item.introduce = item.introduce.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        }
      })
      // 获取最新需求
      let result = await getProductList({ status: 1, serviceType: 1, pageNum: 1, pageSize: 6 });
      this.demandList = result.rows;
      result.rows.forEach(item => {
        if (item.introduce) {
          item.introduce = item.introduce.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        }
      })
    },
    goShop() {
      this.$store.state.activeMenu = '/shop'
      this.$router.push('/shop')
      sessionStorage.setItem("path", JSON.stringify(path))
    },
    async goDetail(item) {
      await getPageView({ id: item.id })
      await this.getProductList();
      // 打开的新页面中浏览量需要同步更新
      const info=this.list.find(el=>el.id==item.id);
      sessionStorage.setItem("productInfo", JSON.stringify(info))
      const path = this.$router.resolve({ name: "Details" })
      window.open(path.href, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  margin-top: 60px;

  .no-data {
    text-align: center;
    font-size: 20px;
    margin: 20px;
  }

  .banner-container {
    height: 470px;
    // background: url('@/assets/images/banner.png');
    // background-size: cover;
    position: relative;

    .el-carousel {
      z-index: 5;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      position: absolute;
      left: 32%;
      top: 20%;
      transform: translateX(-50%);
      height: 100px;
      font-size: 70px;
      font-weight: 500;
      line-height: 100px;
      white-space: no-wrap;
      z-index: 6;
      color: #000;
    }

    button {
      position: absolute;
      left: 32%;
      bottom: 20%;
      transform: translateX(-50%);
      width: 200px;
      height: 56px;
      border: 1px solid #fff;
      background-color: transparent;
      font-size: 26px;
      font-weight: 400;
      line-height: 44px;
      border-radius: 4px;
      border: none;
      z-index: 6;
      cursor: pointer;
      color: #fff;

      &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        bottom: -5px;
        right: -5px;
        background: linear-gradient(to right, #03a9f4, #f441a5, #ffeb3b, #09a8f4);
        background-size: 400%;
        border-radius: 50px;
        z-index: -1;
        /* 设置模糊度 显示发光效果 */
        filter: blur(20px);
      }

      &:hover {
        animation: streamer 8s infinite;
      }

      &:hover::before {
        animation: streamer 8s infinite;
      }
    }


    @keyframes streamer {
      100% {
        /* 背景位置 */
        background-position: -400% 0;
      }
    }

    .phone {
      width: 220px;
      height: 40px;
      border: 2px solid #FF8800;
      border-radius: 40px;
      position: fixed;
      top: 1%;
      right: 3%;
      cursor: pointer;
      z-index: 20;

      img {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        vertical-align: middle;
        margin-bottom: 7px;
      }

      .phone-number {
        font-size: 20px;
        color: #FF8800;
        line-height: 37px;
        margin-left: 8px;
      }
    }

  }

  section {
    position: relative;
    width: 100%;
    height: 80px;
    background: #fff;
    border: 1px solid rgba(153, 153, 153, 0.2);

    ul {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 71%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        width: 110px;
        font-size: 17px;

        p {
          text-align: center;
        }

        .item-data {
          font-size: 22px;
          font-weight: 600;
          color: #333;
          line-height: 30px;
          text-align: center;

          &::after {
            content: "\002b";
            color: #1261ff;
          }
        }
      }
    }
  }

  .product-container {
    width: 70%;
    margin: 20px auto;
    background: #fff;
    padding: 10px;
    border-radius: 5px;

    .product-top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px;

      &::after {
        content: '';
        width: 31%;
      }

      .el-card {
        width: 31%;
        height: 300px;
        margin-bottom: 16px;
        position: relative;

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .content {
          height: 150px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        }

        .footer {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          color: #999;
          margin-top: 10px;
          position: absolute;
          bottom: 10px;
          left: 0;

          .day {
            width: 100px;
            font-weight: 600;
            color: skyblue;
          }

          .btn {
            .canyu {
              border-radius: 20px 0 0 20px;
            }

            .fabu {
              border-radius: 0 20px 20px 0;
              margin-left: 0;
            }
          }

        }
      }
    }

    h2 {
      margin-bottom: 15px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px;

      &::after {
        content: '';
        width: 360px;
      }

      li {
        width: 360px;
        height: 250px;
        text-align: center;
        font-size: 18px;
        color: #333;
        text-align: center;
        cursor: pointer;

        img {
          width: 100%;
          height: 90%;
          border-radius: 5px;
        }
      }
    }
  }

  .suggest-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-card {
      width: 500px;
      padding: 20px;

      h3 {
        margin-top: 10px;
        color: #666;

        img {
          width: 20px;
          vertical-align: middle;
          margin-right: 8px;
        }
      }

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      ::v-deep .el-form {
        margin-top: 20px;

        .el-input {

          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;

          }

          input::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }

      }

      .btn {
        width: 100%;
        text-align: center;
        margin-top: 10px;

      }

      .el-button {
        width: 140px;
        margin-top: 10px;
        background: #ff855f;
        border: none;
      }

      .tips {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        color: #999;
      }
    }
  }

  footer {
    width: 100%;
    height: 200px;
    background: #212121;
    margin-top: 10px;
    padding: 25px;
    position: relative;

    .footer-left {
      position: absolute;
      left: 20%;
      width: 50%;
      text-decoration: underline;
      color: #fff;

      ul {
        height: 30px;
        margin-bottom: 10px;

        li {
          height: 30px;
          margin-right: 50px;
          float: left;

          a {
            font-size: 18px;
            line-height: 30px;
            display: block;
            color: #fff;
          }
        }
      }

      hr,
      h3,
      .info {
        width: 33%;
        margin-bottom: 10px;
      }

      h3 {
        text-decoration: underline;
      }

    }

    .footer-right {
      display: flex;
      align-items: center;
      gap: 50px;
      color: #fff;
      position: absolute;
      left: 40%;

      .footer-right-item {
        width: 120px;
        white-space: nowrap;
        text-align: center;
      }

      img {
        width: 100%;
      }
    }
  }
}
</style>
