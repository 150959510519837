export default {
    bind(el, binding) {
        el.style.cursor = 'move';
        el.onmousedown = function (e) {
            // 获取鼠标到绑定元素的x和y坐标
            const oLeft = el.getBoundingClientRect().left
            const oTop = el.getBoundingClientRect().top
            const x=e.clientX - oLeft
            const y=e.clientY - oTop
            document.onmousemove = function (event) {
                event.preventDefault()
                // 获取绑定元素距离浏览器左边的距离
                const disX = event.clientX - x
                const disY = event.clientY - y
                el.style.left = disX + 'px'
                el.style.top = disY + 'px'
            }
            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmousedown = null;
            };
        }
    }
}