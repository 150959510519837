import axios from "axios";
import { Notification, MessageBox, Message, Loading } from "element-ui";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["tenantId"] = "timeAndSpaceMarket202409041440"; // 让每个请求携带自定义token 请根据实际情况自行修改
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: process.env.VUE_APP_BASE_API,
    // baseURL:'https://market.spatialinfo.cn/api',
    baseURL:'https://www.zhenxuan.net.cn/api',

    // 超时
    timeout: 24 * 10000,
});

// request拦截器

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        const errorCode = {
            '401': '认证失败，无法访问系统资源',
            '403': '当前操作没有权限',
            '404': '访问资源不存在',
            'default': '系统未知错误，请反馈给管理员'
        }

        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode["default"];
        // 二进制数据则直接返回
        if (
            res.request.responseType === "blob" ||
            res.request.responseType === "arraybuffer"
        ) {
            return res.data;
        }
        if (code === 401) {
            MessageBox.confirm(
                "登录状态已过期，您可以继续留在该页面，或者重新登录",
                "系统提示",
                {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    store.dispatch("LogOut").then(() => {
                        location.href = "#/login";
                        // console.log(this)
                        // console.log(this.$router)
                        // this.$router.push("/login");
                    });
                })
                .catch(() => { });
            return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
        } else if (code === 500) {
            Message({
                message: msg,
                type: "error",
            });
            return Promise.reject(new Error(msg));
        } else if (code != 200) {
            Notification.error({
                title: msg,
            });
            return Promise.reject("error");
        } else {
            return res.data;
        }
    },
    (error) => {
        console.log("err" + error);
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);


export default service;
