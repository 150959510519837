<template>
  <div id="app">
    <div class="header-box">
      <div class="top-nav">
        <img @click="$router.push('/')" src="@/assets/icons/logo.png" alt="">
        <h1 @click="$router.push('/')">甄选型</h1>
        <ul>
          <li :class="activeMenu == '/' ? 'active-li' : ''" @click="jumpPage('/')">首页</li>
          <li :class="activeMenu == '/shop' ? 'active-li' : ''" @click="jumpPage('/shop')">甄选产品</li>
          <li :class="activeMenu == '/news' ? 'active-li' : ''" @click="jumpPage('/news')">甄选合作</li>
          <li :class="activeMenu == '/join' ? 'active-li' : ''" @click="jumpPage('/join')">关于甄选</li>
        </ul>
        <div class="phone">
          <img src="@/assets/icons/phone.png" alt="">
          <span class="phone-number">{{ phone }}</span>
        </div>
      </div>
    </div>
    <router-view />
    <footer>
      <div class="footer-left">
        <ul>
          <li>
            <a href="" @click="handleFooterClick($event, '/join')">关于甄选</a>
          </li>
          <li>
            <a href="" @click="handleFooterClick($event, '/news')">甄选合作</a>
          </li>
          <li>
            <a href="" @click="handleFooterClick($event, '')">反馈意见</a>
          </li>
        </ul>
        <!-- <hr> -->
        <p>{{ plateInfo[0]?.dictValue }}</p>
        <div class="info">
          <p>地址:&nbsp;{{ plateInfo[1]?.dictValue }}</p>
          <p>电话:&nbsp; {{ plateInfo[2]?.dictValue }}</p>
          <p>邮箱:&nbsp; {{ plateInfo[3]?.dictValue }}</p>
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-right-item" v-for="item in customerList" :key="item.id">
          <img :src="item.imageUrl" alt="">
          <p>甄选官：{{ item.name }}</p>
        </div>
      </div>
      <div class="footer-img">
      </div>
    </footer>
    <!-- 弹窗 -->
    <div class="suggest-container" v-show="suggestVisible">
      <el-card>
        <h3>请写下您宝贵的建议</h3>
        <h3>求购咨询电话：
          <img src="@/assets/icons/phone.png" alt="">
          <span style="color:orange;">{{ phone }}</span>
        </h3>
        <div class="close" @click="handleClose">X</div>
        <el-form ref="suggestForm" :model="suggestForm" :rules="suggestRules" label-width="70px">
          <el-form-item label="内容:" prop="content">
            <el-input type="textarea" rows="5" resize="none" v-model="suggestForm.content" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="电话:" prop="phone">
            <el-input v-model="suggestForm.phone" placeholder="请输入您的电话"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:" prop="email">
            <el-input v-model="suggestForm.email" placeholder="请输入您的邮箱"></el-input>
          </el-form-item>
        </el-form>
        <span class="tips">注：若您的建议被采纳，我们会联系您并赠送精美小礼物。</span>
        <div class="btn">
          <el-button type="primary" round @click="submitSuggest">提交</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { addMessageDemand, getTabData, getCustomer } from '@/api'

export default {

  data() {
    const validatePhone = (rules, value, callback) => {
      console.log("value", value)
      const reg = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
      if (!reg) {
        callback('请输入正确的手机号')
      } else {
        callback();
      }
    }
    const validateEamil = (rules, value, callback) => {
      const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(value)
      if (!reg) {
        callback('请输入正确的邮箱')
      } else {
        callback();
      }
    }
    return {
      phone: "",
      suggestVisible: false,
      plateInfo: [],
      suggestForm: {},
      suggestRules: {
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validatePhone, trigger: 'blur' }
        ],
        email: [
          { required: true, validator: validateEamil, trigger: 'blur' }
        ],
      },
      customerList: [],
    }
  },
  computed: {
    activeMenu() {
      return this.$store.state.activeMenu
    }
  },
  created() {
    this.getDict();
    this.getPlateInfo()
    this.getCustomerList()

  },
  mounted() {
    const path = JSON.parse(sessionStorage.getItem('path')) || '/'
    this.$store.state.activeMenu = path
  },
  methods: {
    async getCustomerList() {
      let res = await getCustomer();
      console.log(res)
      this.customerList = res.rows;
    },
    async getDict() {
      let res = await getTabData({ dictType: 'phone' })
      this.phone = res.rows[0].dictValue
    },
    async getPlateInfo() {
      let res = await getTabData({ dictType: 'plateform' })
      this.plateInfo = res.rows;
    },
    async submitSuggest() {
      this.$refs.suggestForm.validate(async valid => {
        if (valid) {
          this.suggestForm.type = this.submitType;
          const res = await addMessageDemand(this.suggestForm)
          if (res.code != 200) {
            this.$message.error(res.msg)
          } else {
            this.$message.success('提交成功')
            this.handleClose();
          }
        }
      })
    },
    handleFooterClick(e, path) {
      e.preventDefault();
      if (!path) {
        this.suggestVisible = true
        this.submitType = "FANKUI"
        return;
      }
      this.$router.push(path)
      sessionStorage.setItem("path", JSON.stringify(path))
      this.$store.state.activeMenu = path
    },
    jumpPage(path) {
      sessionStorage.setItem("path", JSON.stringify(path))
      this.$store.state.activeMenu = path
      this.$router.push(path)
    },
    handleClose() {
      this.dialogVisible = false;
      this.suggestVisible = false;
      this.suggestForm = {};
      this.$refs.suggestForm.clearValidate();
    },
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

#app {
  width: 100vw;
  height: 100vh;
  background: #fafafa;

  .header-box {
    width: 100%;
    padding: 0 24px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 10;

    .top-nav {
      height: 60px;
      display: flex;
      position: relative;

      img {
        margin-right: 10px;
        cursor: pointer;
      }

      h1 {
        line-height: 60px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        letter-spacing: 5px;
        font-family: biaoti;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        min-width: 640px;
        position: absolute;
        left: 360px;
        top: 0;
        display: flex;
        justify-content: space-around;

        li {
          height: 60px;
          line-height: 60px;
          text-align: center;
          width: 80px;
          font-size: 18px;
          font-weight: 400;
          float: left;
          cursor: pointer;
          color: #1f2329;

          &:hover {
            border-bottom: 2px solid #1261ff;
            border-width: 3px;
          }
        }

        .active-li {
          border-bottom: 2px solid #1261ff;
          border-width: 3px;
        }
      }

      .phone {
        width: 220px;
        height: 40px;
        border: 2px solid #FF8800;
        border-radius: 40px;
        position: fixed;
        top: 1%;
        right: 3%;
        cursor: pointer;
        z-index: 20;

        img {
          width: 25px;
          height: 25px;
          margin-left: 10px;
          vertical-align: middle;
          margin-bottom: 7px;
        }

        .phone-number {
          font-size: 20px;
          color: #FF8800;
          line-height: 37px;
          margin-left: 8px;
        }
      }
    }
  }

  footer {
    width: 100%;
    height: 200px;
    background: #252525;
    margin-top: 10px;
    padding: 25px;
    position: relative;

    .footer-left {
      position: absolute;
      left: 15%;
      width: 100%;
      color: #fff;
      z-index: 2;

      ul {
        height: 30px;
        margin-bottom: 10px;

        li {
          height: 30px;
          margin-right: 50px;
          float: left;

          a {
            font-size: 18px;
            line-height: 30px;
            text-decoration:none;
            display: block;
            color: #fff;
          }
        }
      }

      hr {
        border-color: #757575;
      }

      hr,
      h3,
      .info {
        width: 33%;
        line-height: 27px;
        margin-bottom: 10px;
        margin-top: 8px;
      }
      h3{
        font-weight: 100;
      }

    }

    .footer-right {
      width: 27%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      color: #fff;
      position: absolute;
      left: 58%;
      z-index: 2;

      .footer-right-item {
        width: 120px;
        white-space: nowrap;
        text-align: center;
      }

      img {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .footer-img {
      width: 32%;
      height: 200px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background: url('@/assets/images/footer-bg.jpg')100% 100% no-repeat;
    }
  }

  .suggest-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-card {
      width: 500px;
      padding: 20px;

      h3 {
        margin-top: 10px;
        margin-bottom: 15px;
        color: #666;

        img {
          width: 20px;
          vertical-align: middle;
          margin-right: 8px;
        }
      }

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      ::v-deep .el-form {
        margin-top: 20px;

        .el-input {

          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;

          }

          input::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }

      }

      .btn {
        width: 100%;
        text-align: center;
        margin-top: 10px;

      }

      .el-button {
        width: 140px;
        margin-top: 10px;
        background: #ff855f;
        border: none;
      }

      .tips {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 13px;
        color: #999;
      }
    }
  }
}
</style>
